// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// DataTables
@import '~datatables.net-dt/css/jquery.dataTables.min.css';

// Font Awesome
@import 'node_modules/font-awesome/scss/font-awesome.scss';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Selectize
@import '~@selectize/selectize/dist/css/selectize.bootstrap4.css';

#campaign-report-chart-div {
    width: 100%;
    height: 500px;
}

#toast-box {
    right: 0;
    left: unset;
}
